




















































import cambioscontrasenasperdidasModule from "@/store/modules/cambioscontrasenasperdidas-module";
import { Component, Vue } from "vue-property-decorator";
import { cambioscontrasenasperdidas } from "@/shared/dtos/cambioscontrasenasperdidas";
@Component({ $_veeValidate: { validator: "new" } })
export default class pwdlost extends Vue {
  private objpwslost = new cambioscontrasenasperdidas();
  private ver_mensaje: boolean = false;
  private submit() {
    this.objpwslost.url_production = (
      window.location.href
    ).replace("lostpwd", "");
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        cambioscontrasenasperdidasModule
          .guardarcambioscontrasenasperdidas(this.objpwslost)
          .then(() => (this.ver_mensaje = true));
      }
    });
  }
}
